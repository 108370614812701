







import Vue from 'vue'
import { HCard } from '@happytal/bo-ui-library'
export default Vue.extend({
    components: {
        HCard
    },

    props: {
        image: String,
        description: {
            type: String,
            default: ''
        }
    },

    computed: {
        bannerStyle(): Partial<CSSStyleDeclaration> {
            return {
                backgroundImage: this.image ? `url("${this.image}")` : ''
            }
        },
        descriptionParsed(): string {
            return this.description.replace(/(\r\n|\n|\r)/g, "<br />") || ''
        }
    }
})
