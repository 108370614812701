






































import Vue, {PropType} from 'vue'

import { ServiceAsShop } from '@/types/happydom-store'

import { HCarousel, HCard } from '@happytal/bo-ui-library'
import SeparatorLine from '@/components/SeparatorLine.vue'
import DescriptionWithToggle from '@/components/DescriptionWithToggle.vue'

export default Vue.extend({
    components: {
        HCarousel,
        HCard,
        SeparatorLine,
        DescriptionWithToggle
    },

    props: {
        title: String,
        shops: {
            type: Array as PropType<ServiceAsShop[]>,
            required: true
        },
        selectedShopCode: {
            type: String,
            required: true
        }
    },

    computed: {
        shopsOrdered(): ServiceAsShop[] {
            return [...this.shops].sort(
                (s1, s2) => {
                    if (s1.totalAmount === 0 && s2.totalAmount !== 0) return 1
                    if (s1.totalAmount !== 0 && s2.totalAmount === 0) return -1
                    return s1.totalAmount - s2.totalAmount
                }
            )
        }
    },

    methods: {
        onClickShopCard(shop: ServiceAsShop, index: number): void {
            this.$emit('select-shop', shop)
        }
    }
})
