




















import Vue, { PropType } from 'vue'

import { SelectionOption } from '@/types/components'

export default Vue.extend({
    props: {
        options: {
            type: Array as PropType<SelectionOption[]>
        }
    }
})
