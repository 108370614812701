import { render, staticRenderFns } from "./ServicePresentation.vue?vue&type=template&id=11501056&scoped=true&"
import script from "./ServicePresentation.vue?vue&type=script&lang=ts&"
export * from "./ServicePresentation.vue?vue&type=script&lang=ts&"
import style0 from "./ServicePresentation.vue?vue&type=style&index=0&id=11501056&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11501056",
  null
  
)

export default component.exports