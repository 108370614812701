



















import Vue from 'vue'
export default Vue.extend({
    props: {
        textFold: {
            type: String,
            default: 'Plus de détails'
        },
        textUnfold: {
            type: String,
            default: 'Moins de détails'
        },
        foldByDefault: {
            type: Boolean,
            default: true
        }
    },

    data(){
        return {
            fold: this.foldByDefault,
            height: 0
        }
    },

    computed: {
        cropperStyle(): Partial<CSSStyleDeclaration> {
            const height = this.fold ? '0' : `${this.height}px`
            return { height }
        }
    },

    mounted(){
        const descriptionContentEl = this.$refs.descriptionContent as HTMLElement
        this.height = descriptionContentEl.offsetHeight
    },

    methods: {
        toggle(): void {
            this.fold = !this.fold
        }
    }
})
