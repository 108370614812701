




























































/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Vue, {PropType} from 'vue'

import { ServiceOption, ServiceOptionValue, PayloadUpdateServiceOptionValueSelect } from '@/types/happydom-store'

import { HCard, HFormField, HRadioGroup, HRadio, HSwitch, HRow, HCol } from '@happytal/bo-ui-library'
import SeparatorLine from '@/components/SeparatorLine.vue'

export default Vue.extend({
    components: {
        HCard,
        HFormField,
        HRadioGroup,
        HRadio,
        HSwitch,
        HRow,
        HCol,
        SeparatorLine
    },

    props: {
        booleanOptions: {
            type: Array as PropType<ServiceOption[]>
        },
        valueListOptions: {
            type: Array as PropType<ServiceOption[]>
        },
    },

    data(){
        return {
            displayTemplate: false,
            booleanModels: [] as string[],
            valueListModels: [] as boolean[][]
        }
    },

    computed: {
        disableAllValueListOptions(): boolean {
            return this.booleanModels.length ? this.booleanModels.every(bool => bool === '') : false
        }
    },

    mounted(){
        for (let i = 0; i < this.booleanOptions.length; i++) {
            this.booleanModels.push('')
        }
        this.valueListOptions.forEach( (option, index) => {
            this.valueListModels.push([])

            option.values?.forEach( value => {
                this.valueListModels[index].push(false)
            })
        })

        this.displayTemplate = true
    },

    methods: {
        updateSelectionBooleanOption(option: ServiceOption, stringValue: string) {
            const payload: PayloadUpdateServiceOptionValueSelect = {
                option: {...option},
                optionValue: {
                    value: stringValue === 'true' ? true : false
                },
                isSelected: true
            }
            this.$emit('update-boolean-option-select', payload)
        },

         updateSelectionValueListOption(optionIndex: number, valueIndex: number, option: ServiceOption, optionValue: ServiceOptionValue, value: boolean) {
            if (option.orderConfiguration?.maximumNumberOfElements === 1) {
                for(let i = 0; i < this.valueListModels[optionIndex].length; i++) {
                    if (i !== valueIndex) this.valueListModels[optionIndex][i] = false
                    else this.valueListModels[optionIndex][i] = value
                }
            } else {
                this.valueListModels[optionIndex][valueIndex] = value
            }

            const payload: PayloadUpdateServiceOptionValueSelect = {
                option: {...option},
                optionValue: {...optionValue},
                isSelected: value
            }

            this.$emit('update-value-list-option-select', payload)
            
        }
    }

})
