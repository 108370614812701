































































import { mapState, mapGetters } from 'vuex'
import mixins from 'vue-typed-mixins'
import Routes from '@/constants/routes'
import { frequencies } from '@/constants/subscription'

import loadRouteMixin from '@/mixins/loadRouteMixin'
import toasts from '@/mixins/toasts'
import institutionMixin from '@/mixins/institutionMixin'
import residentMixin from '@/mixins/residentMixin'
import currentUserMixin from '@/mixins/currentUserMixin'

import {
    HRow,
    HCol,
    HBtn,
    HCard,
    HQuantity,
    HFormField,
    HInput,
    HPopover,
    HIcon
} from '@happytal/bo-ui-library'
import MainContainer from '@/views/main/MainContainer.vue'
import CatalogItemHeader from './components/CatalogItemHeader.vue'
import BackButton from '@/components/BackButton.vue'
import CatalogItemtInfos from './components/CatalogItemtInfos.vue'
import CatalogItemSubscription from './components/CatalogItemSubscription.vue'
import SeparatorLine from '@/components/SeparatorLine.vue'
import DropdownSelector from '@/components/selectors/DropdownSelector.vue'
import ThumbSelector from '@/components/selectors/ThumbSelector.vue'
import ServicePresentation from './components/ServicePresentation.vue'
import CatalogItemSelection from './components/CatalogItemSelection.vue'
import CatalogItemBuyBox from './components/CatalogItemBuyBox.vue'
import ServiceSettingsSelection from './components/ServiceSettingsSelection.vue'
import CatalogItemShops from './components/CatalogItemShops.vue'
import SuggestionWarningPopin from '@/components/popins/SuggestionWarningPopin.vue'

import { Location } from 'vue-router'
import {
    PCP,
    PayloadUpdateServiceOptionValueSelect,
    ServiceOption,
    ServiceOptions,
    ServiceAsShop,
    SelectedBooleanOptionValue,
    SelectedValueListOptionValue
} from '@/types/happydom-store'

import {
    CatalogBreadcrumbItem,
    BuyMode,
    FrequencySelectOption
} from '@/types/components'
import { IFrontCategoryDto } from '@happytal/ecommerce-api'
import {
    ServiceDto,
    IResidentDto
} from '@happytal/happydom-api'
import { EC_ServiceModel } from '@/types/api-missing'

export default mixins(loadRouteMixin, toasts, residentMixin, currentUserMixin, institutionMixin).extend({
    components: {
        HRow,
        HCol,
        HCard,
        HBtn,
        HQuantity,
        HFormField,
        HInput,
        HPopover,
        HIcon,
        MainContainer,
        CatalogItemHeader,
        BackButton,
        CatalogItemtInfos,
        CatalogItemSubscription,
        SeparatorLine,
        DropdownSelector,
        ThumbSelector,
        ServicePresentation,
        CatalogItemSelection,
        CatalogItemBuyBox,
        ServiceSettingsSelection,
        CatalogItemShops,
        SuggestionWarningPopin
    },

    props: ['resident_id', 'service', 'service_model_id'],

    data(){
        return {
            //Current PCP
            currentPcpQuantity: 0,

            //Other
            errorInBuyBox: '',
            suggestionWarningOpen: false
        }
    },

    computed: {
        //Store categories
        areCategoriesLoaded(): boolean {
            return this.$store.state.dataCatalogCategories.categoriesLoaded as boolean
        },

        // Store service state
        category(): IFrontCategoryDto | undefined {
            return this.$store.state.dataService.category
        },
        services(): ServiceDto[] {
            return this.$store.state.dataService.services
        },
        serviceModel(): EC_ServiceModel | undefined {
            return this.$store.state.dataService.serviceModel
        },
        serviceOptions(): ServiceOptions {
            return this.$store.state.dataService.serviceOptions
        },
        selectedBooleanOptionValues(): SelectedBooleanOptionValue[] {
            return this.$store.state.dataService.selectedBooleanOptionValues
        },
        selectedValueListOptionValues(): SelectedValueListOptionValue[] {
            return this.$store.state.dataService.selectedValueListOptionValues
        },
        isUserHasManifestedAChoice(): boolean {
            return this.$store.state.dataService.isUserHasManifestedAChoice
        },

        // Store service getters
        serviceSelected(): ServiceAsShop {
            return this.$store.getters['dataService/serviceSelected'] || {}
        },
        availableServicesAsShops(): ServiceAsShop[] {
            return this.$store.getters['dataService/availableServicesAsShops']
        },

        // Store buy box state
        subscriptionMode(): boolean {
            return this.$store.state.dataBuyBox.subscriptionMode
        },
        suggestionEmitterName(): string {
            return this.$store.state.dataBuyBox.suggestionEmitterName
        },
        isCurrentSubscription(): boolean {
            return this.$store.state.dataBuyBox.isCurrentSubscription
        },
        isSuggestionEnabled(): boolean {
            return this.$_resident?.enableSuggestion
        },
        subscriptionFrequency(): FrequencySelectOption | null {
            return this.$store.state.dataBuyBox.subscriptionFrequency
        },
        quantity(): number {
            return this.$store.state.dataBuyBox.quantity
        },

        // Service page computed
        breadcrumbItems(): CatalogBreadcrumbItem[] {
            const breadcrumbItems: CatalogBreadcrumbItem[] = [
                {
                    type: 'catalog',
                    label: 'Catalogue',
                    route: {
                        name: Routes.ResidentCatalog
                    },
                    iconForMobile: 'BuildingStoreIcon'
                }
            ]
            if (this.category?.name) {
                const route = {
                    name: Routes.ResidentCatalog,
                    params: {}
                } as Location

                if (this.category?.id && route.params) {
                    route.params.category_id = this.category.id
                }
                breadcrumbItems.push({
                    type: 'category',
                    label: this.category.name,
                    route
                })
            }
            if (this.serviceModel?.title) {
                breadcrumbItems.push({
                    type: 'element',
                    label: this.serviceModel.title
                })
            }
            return breadcrumbItems
        },
        backButtonLabel(): string {
            return this.category?.name ? `Retour à ${this.category.name }` : ''
        },
        backButtonRoute(): Location | undefined {
            if (!this.category) {
                return undefined
            }
            return {
                name: Routes.ResidentCatalog,
                params: {
                    category_id: this.category?.id ? this.category.id : ''
                }
            }
        },

        isEditionAllowed(): boolean {
            return this.$aclChecker.some(this.$acl, [
                'isTutor',
                'isFamily',
                'isHappytalSupport',
                'isHappytalEmployee',
                'canEmitSuggestions'
            ])
        },

        canEmitSuggestions(): boolean {
            return this.$aclChecker.some(this.$acl, [
                'canEmitSuggestions',
            ])
        },

        residentPcp(): PCP {
            return this.$store.getters['dataPcps/getResidentPcp'](this.$_residentId) as PCP
        },

        serviceTotalPrice(): number {
            return this.serviceSelected?.totalAmount ?? 0
        },

        serviceInfos(): any {
            return {
                brand: this.serviceModel?.title || '',
                shop: this.serviceSelected?.shop?.name || '',
                title: "Votre panier",
                price: this.serviceTotalPrice,
                displayLinkDetail: false,
                type: 'service',
                shortDescription: "Votre prix évoluera en fonction des prestations choisies dans votre sélection"
            }
        },

        selectionOptions(): any[] {
            const selectionOptions = this.$store.getters['dataService/optionsValuesSelected'].map( ({ label, amount }: any) => ({label, amount}) )
            const pricePerUnit = this.serviceSelected?.pricing?.pricePerUnit
            const totalAmount = this.serviceSelected?.totalAmount
            if (totalAmount && pricePerUnit) {
                selectionOptions.unshift({
                    amount: pricePerUnit,
                    code: null,
                    label: "Prix de base",
                    value: null
                })
            }
            return selectionOptions
        },

        booleanOptions(): ServiceOption[] {
            if (!this.selectedBooleanOptionValues.length && !this.isUserHasManifestedAChoice) return this.serviceOptions['BOOLEAN']

            return this.serviceOptions['BOOLEAN'].map( (opt: ServiceOption) => {
                const values = opt.values.map( val => ({
                    ...val,
                    disabled: false
                }))
                return { ...opt, values}
            })

        },
        valueListOptions(): ServiceOption[] {
            if (!this.selectedBooleanOptionValues.length && !this.selectedValueListOptionValues.length && !this.isUserHasManifestedAChoice) return this.serviceOptions['VALUE_LIST']

            return this.serviceOptions['VALUE_LIST'].map( (opt: ServiceOption) => {
                const values = opt.values.map( val => ({
                    ...val,
                    disabled: this.checkIfValueOptionDisabled(opt.code, val.code ?? '')
                }))
                return { ...opt, values}
            })
        },

        buyMode(): BuyMode {
            if (this.subscriptionMode && this.subscriptionFrequency) {
                return {
                    id: 'subscription',
                    name: `Abonnement`,
                    label: '',
                    deactivated: this.isCurrentSubscription ? true : false
                }
            }
            else return {
                id: 'once',
                name: 'Une seule fois',
                label: '',
                deactivated: false
            }
        },
    },

    watch: {
        serviceSelected(service): void {
            this.$store.dispatch('dataBuyBox/updateAmount', service?.totalAmount || 0)
        }
    },

    async created(){
        this.clearDependenciesStore()

        try  {
            if (!this.areCategoriesLoaded) {
                await this.$store.dispatch('dataCatalogCategories/getCategories', {
                    institutionId: this.$_currentInstitutionId
                })
            }

            if (!this.residentPcp) {
                try {
                    await this.$store.dispatch('dataPcps/getResidentPcp', {
                        residentId: this.$_residentId
                    })
                } catch {
                    console.log('No shopping list for current resident')
                }
            }

            await this.$store.dispatch('dataService/getService', {
                serviceModelId: this.service_model_id,
                institutionId: this.$_currentInstitutionId
            })

            this.checkIfCurrentSubscription()

        } catch (error) {
            console.error(error)
            this.$_showErrorToast(`Une erreur s'est produite pendant le chargement du service.`)
        } finally {
            this.$_emitLoadRoute(true)
        }
    },

    beforeRouteLeave(to, from, next) {
        this.clearDependenciesStore()
        next()
    },

    methods: {
        getValueOptionInServiceSelected(optionCode: string, valueKey: string): any {
            return this.$store.getters['dataService/getValueOptionInServiceSelected'](optionCode, valueKey)
        },

        checkIfValueOptionDisabled(optionCode: string, valueKey: string): boolean {
            return this.$store.getters['dataService/isValueOptionDisabled'](optionCode, valueKey)
        },

        getPcpQuantityByProductId(productId: string, type: string): number {
            return this.$store.getters['dataPcps/getQuantity'](this.resident_id, productId, type)
        },

        clearDependenciesStore(): void {
            this.$store.dispatch('dataService/resetServiceState')
            this.$store.dispatch('dataBuyBox/resetState')
        },

        clearOptionsAndBuyBox(): void {
            this.$store.dispatch('dataService/resetOptionsSelected')
            this.$store.dispatch('dataBuyBox/resetState')
        },

        checkIfCurrentSubscription(): void {
            const currentSubscription = this.residentPcp?.shoppingListServices?.find(({ meta }) => this.service_model_id === meta?.serviceModel?.code)

            if (currentSubscription) {
                this.$store.dispatch('dataBuyBox/updateIsCurrentSubscription', true)
                this.$store.dispatch('dataBuyBox/updateSubscriptionFrequency', {
                    value: currentSubscription.frequencyInMonth,
                    text: frequencies[currentSubscription.frequencyInMonth]
                })
            }
        },

        async onClickAddToBasketBtn(): Promise<void> {
            if (this.checkIfMandatoryOptionsAreSelected()) {
                try {
                    if (this.serviceSelected) {
                        const booleanOptions = this.selectedBooleanOptionValues.map((opt: SelectedBooleanOptionValue) => {
                            return {
                                code: opt.optionCode,
                                type: 'BOOLEAN',
                                value: opt?.value?.value,
                            }
                        })
                        const valueListOptions = this.selectedValueListOptionValues.map((opt: SelectedValueListOptionValue) => {
                            return {
                                code: opt.optionCode,
                                type: 'VALUE_LIST',
                                values: opt?.values.map(val => ({ code: val.code })),
                            }
                        })
                        const draftProduct = {
                            id: this.serviceSelected.code,
                            type: 'service',
                            photo: this.serviceModel?.publicData?.imageUrl || '',
                            title: this.serviceModel?.title || '',
                            description: this.serviceModel?.description || '',
                            legend: '',
                            price: this.serviceSelected.totalAmount,
                            frequency: this.subscriptionMode ? this.subscriptionFrequency?.value : null,
                            quantity: this.quantity,
                            meta: {
                                service: this.serviceSelected,
                                serviceModel: this.serviceModel,
                                options: [...booleanOptions, ...valueListOptions]
                            }
                        }

                        await this.$store.dispatch('dataDrafts/add', {
                            userId: this.$_currentUserId,
                            residentId: this.resident_id,
                            type: this.buyMode.id,
                            draftProduct
                        })

                        if (this.$gtm.enabled()) {
                            this.$gtm.trackEvent({
                                event: 'add_to_cart',
                                currency: 'EUR',
                                value: this.serviceSelected.totalAmount * this.quantity,
                                ecommerce: {
                                    items: [{
                                        item_id: this.serviceSelected.code,
                                        item_name: this.serviceModel?.title || '',
                                        price: this.serviceSelected.totalAmount,
                                        itemcategory_: this.category?.name || '',
                                        item_category_2: '',
                                        quantity: this.quantity
                                    }]
                                },
                                buyMode: this.buyMode.id
                            })
                        }
                        document.body.scrollIntoView({behavior: "smooth"})
                    }
                } catch (e) {
                    this.$_showErrorToast(`Une erreur est survenue lors de la validation du panier.`)
                    console.error(e)
                }
            }
        },

        getResidentTutor (resident: IResidentDto) {
            return resident?.userInChargeOfResident
        },

        isResidentHasTutor (resident: IResidentDto) {
            return this.getResidentTutor(resident) != null
        },

        showSuggestionWarning (visible: boolean) {
            this.suggestionWarningOpen = visible
        },

        isEhpadUser (): boolean {
            return this.$aclChecker.some(this.$acl, [
                'isEhpadEmployee',
                'isEhpadManager'
            ])
        },

        goToResidentProfile() {
            this.$router.push({
                name: Routes.ResidentProfile,
                params: {
                    resident_id: this.$_resident.id,
                }
            })
        },

        async onClickSendSuggestionBtn(): Promise<void> {
            if (this.checkIfMandatoryOptionsAreSelected()) {
                try {

                    if (this.isEhpadUser() && !this.isResidentHasTutor(this.$_resident)) {
                        this.showSuggestionWarning(true)
                        return
                    }

                    if (this.serviceSelected) {
                        const booleanOptions = this.selectedBooleanOptionValues.map((opt: SelectedBooleanOptionValue) => {
                            return {
                                code: opt.optionCode,
                                type: 'BOOLEAN',
                                value: opt?.value?.value,
                            }
                        })
                        const valueListOptions = this.selectedValueListOptionValues.map((opt: SelectedValueListOptionValue) => {
                            return {
                                code: opt.optionCode,
                                type: 'VALUE_LIST',
                                values: opt?.values.map(val => ({ code: val.code })),
                            }
                        })

                        await this.$store.dispatch('dataSuggestions/createServiceSuggestion', {
                            residentId: this.resident_id,
                            creatorName: this.suggestionEmitterName || this.$_currentUser?.displayName || '',
                            institutionId: this.$_currentInstitutionId,
                            buyMode: this.buyMode.id,
                            serviceCode: this.serviceSelected.code,
                            frequencyInMonth: this.subscriptionMode ? this.subscriptionFrequency?.value : null,
                            quantity: this.quantity,
                            options: [...booleanOptions, ...valueListOptions]
                        })

                        if (this.$gtm.enabled()) {
                            this.$gtm.trackEvent({
                                event: 'create_suggestion_catalog',
                                currency: 'EUR',
                                value: this.serviceSelected.totalAmount,
                                ecommerce: {
                                    items: [{
                                        item_id: this.serviceSelected.code,
                                        item_name: this.serviceModel?.title || '',
                                        price: this.serviceSelected.totalAmount,
                                        itemcategory_: this.category?.name || '',
                                        item_category_2: '',
                                        quantity: this.quantity
                                    }]
                                },
                                buyMode: this.buyMode
                            })
                        }
                        this.$_showInfoToast('Votre suggestion a bien été envoyée !')
                    }
                } catch (e) {
                    this.$_showErrorToast(`Une erreur est survenue lors de l'envoi de la suggestion'.`)
                    console.error(e)
                }
            }
        },

        checkIfMandatoryOptionsAreSelected(canDisplayErrorMessage = true, canClearErrorMessage = true): boolean {
            const checkIfMandatoryOptionsAreSelected = this.$store.getters['dataService/checkIfMandatoryOptionsAreSelected']

            if (this.errorInBuyBox && checkIfMandatoryOptionsAreSelected && canClearErrorMessage) {
                this.errorInBuyBox = ''
            } else if (canDisplayErrorMessage && checkIfMandatoryOptionsAreSelected === false) {
                this.errorInBuyBox = `Certaines options obligatoires n'ont pas été renseignées`
            }

            return checkIfMandatoryOptionsAreSelected
        },

        handleBooleanOptionSelect(payload: PayloadUpdateServiceOptionValueSelect): void {
            this.$store.dispatch('dataService/updateBooleanOptionSelect', payload)
            this.checkIfMandatoryOptionsAreSelected(false, true)
        },

        handleValueListOptionSelect(payload: PayloadUpdateServiceOptionValueSelect): void {
            this.$store.dispatch('dataService/updateValueListOptionSelect', payload)
            this.checkIfMandatoryOptionsAreSelected(false, true)
        },

        handleShopSelect(shop: ServiceAsShop): void {
            this.$store.dispatch('dataService/updateShopSelectByUser', shop.code)
        },
    },
})
